.loading {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.50);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}  

.loading_anim {
  border: 10px solid rgba(0, 0, 0, .1 );
  border-left-color: #9B0008;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loading_img {
  height: 100px;
  /* padding: 40px; */
  margin-top: -118px !important;
  }

@keyframes spin {
  to { transform: rotate(360deg); }
}