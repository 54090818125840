/* body {
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  */
 
.box {
  width: 100%;
  /* border: 1px solid #ddd; */
}

.box form{
  display: block;

}
.box .steps {
  /* border-bottom: 1px solid #ddd; */
}
.box .steps ul {
  overflow: hidden;
}

.box .steps ul li div {
  color: #999;
  margin-bottom: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 45px;
  position: relative;
  background: #f5f5f5;
  width: 160px;
  height: 60px;
}
.box .steps ul li div span {
  font-size: 13px;
}
.box .steps ul li:first-child div {
  width: 135px;
  padding-left: 15px;
}
.box .steps ul li div::before {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}
.box .steps ul li div::after {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.box .steps ul li.done div {
  border-color: #9B0008 !important;
  color: #fff !important;
  background: #9B0008 !important;
}
.box .steps ul li.done div::after {
  border-left: 30px solid #9B0008;
}
.box .steps ul li.active div {
  border-color: #24262df0 !important;
  color: #fff !important;
  background: #24262df0 !important;
}
.box .steps ul li.active div::after {
  border-left: 30px solid #24262df0;
}
 
.box .step-component {
  /* padding: 20px; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.box .btn-component {
  /* padding: 0 0 0 0; */
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 1189px) {
  /* .box .step-component {
    height: 700px;
  } */
  .box .steps ul li div {
    width: 138px;
  }
  .box .steps ul li:first-child div {
    width: 100px;
  }
}
@media (max-width: 987px) {
  .box .steps ul{
    display: none
  }
  .contract .contract-img {
    border-bottom: 1px solid #f5f5f5;
  }
  .contract .contract-img img {
    margin-right: 20px;
  }
  .contract h4 {
    font-size: 20px;
  }
}

@media all and (max-width: 762px){
  /* .box .step-component {
    height: 100%;
  } */
}

@media (max-width: 496px) {
  .contract .contract-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;

  }
}